.statusParent .upToDate {
  color: green;
  padding: 0.2rem 1.5rem;
  border: solid 1px green;
  font-size: smaller;
}

.statusParent .conflict {
  color: red;
  padding: 0.2rem 1.5rem;
  border: solid 1px red;
  font-size: smaller;
}

.row {
  font-size: larger;
  padding: 5%;
}

.activeRow {
  border: 2px green solid;
}